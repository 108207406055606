import * as React from "react"
import PropTypes from "prop-types"
import { Container, Nav, Navbar } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Helmet } from 'react-helmet'

const current = new Date();
const TodayDate = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;


const Header = ({ siteTitle }) => (
  <header>
    <Helmet>
      <script src="https://kit.fontawesome.com/bb17528802.js" crossorigin="anonymous"></script>
    </Helmet>
    <Navbar bg="light" expand="lg">
      <Container fluid>
        <Navbar.Brand href="/">{siteTitle}</Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse>
          <Nav>
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/article">Article</Nav.Link>
            <Nav.Link href="/about">About</Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text>
            Hari ini {TodayDate}
          </Navbar.Text>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
